'use client';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Main = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [extractedText, setExtractedText] = useState('');
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const scrollToFooter = () => {
    const footer = document.querySelector('footer'); 
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const loadDropboxScript = () => {
      const script = document.createElement('script');
      script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
      script.setAttribute('data-app-key', 'YOUR_DROPBOX_APP_KEY');
      script.async = true;
      document.body.appendChild(script);
    };
    loadDropboxScript();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setExtractedText('');
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setExtractedText('');
    }
  };

  const loadPicker = () => {
    window.gapi.load('auth', { 'callback': onAuthApiLoad });
    window.gapi.load('picker', { 'callback': onPickerApiLoad });
  };

  const onAuthApiLoad = () => {
    window.gapi.auth.authorize({
      'client_id': '760650779845-ij25uj2jjqsaghrcvrmrqfdtpsejvqg1.apps.googleusercontent.com',
      'scope': ['https://www.googleapis.com/auth/drive.readonly'],
      'immediate': false
    }, handleAuthResult);
  };

  const handleAuthResult = (authResult) => {
    if (authResult && !authResult.error) {
      createPicker();
    }
  };

  const onPickerApiLoad = () => {
    window.pickerApiLoaded = true;
    createPicker();
  };

  const createPicker = () => {
    if (window.pickerApiLoaded && window.gapi.auth.getToken()) {
      const picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.DOCS_IMAGES)
        .setOAuthToken(window.gapi.auth.getToken().access_token)
        .setDeveloperKey('AIzaSyC4eKvhaAsI49XNwaWped5T49eaxNSphaY')
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    }
  };

  const pickerCallback = (data) => {
    if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
      const file = data[window.google.picker.Response.DOCUMENTS][0];
      const imageUrl = file[window.google.picker.Response.URL];
      setSelectedImage(imageUrl);
      setExtractedText('');
    }
  };

  const dropboxPicker = () => {
    window.Dropbox.choose({
      success: (files) => {
        const file = files[0];
        setSelectedImage(file.link);
        setExtractedText('');
      },
      linkType: 'preview',
      multiselect: false,
      extensions: ['.png', '.jpg', '.jpeg'],
    });
  };

  const convertImageToText = async () => {
    if (!selectedImage) {
      alert('Please select an image file');
      return;
    }

    setLoading(true);
    setTimeout(scrollToFooter, 100);

    let base64Image;

    const response = await fetch(selectedImage);
    const blob = await response.blob();
    const reader = new FileReader();

    reader.onloadend = async () => {
      base64Image = reader.result.split(',')[1];
      const encodedParams = new URLSearchParams();
      encodedParams.set('base64ImageData', base64Image);

      const options = {
        method: 'POST',
        url: 'https://image-to-text-ocr4.p.rapidapi.com/api/captcha',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          'X-RapidAPI-Key': 'cca330428dmsh4b459b029c77e3cp1a7504jsn8f61efbba564',
          'X-RapidAPI-Host': 'image-to-text-ocr4.p.rapidapi.com'
        },
        data: encodedParams,
      };

      try {
        const response = await axios.request(options);
        const data = response.data;
        setExtractedText(data.text || 'No text found');
      } catch (error) {
        console.error(error);
        alert('An error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    reader.readAsDataURL(blob);
  };

  const downloadTextFile = () => {
    const element = document.createElement('a');
    const file = new Blob([extractedText], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'extracted-text.txt';
    document.body.appendChild(element);
    element.click();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(extractedText).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <section className='mx-10 bg-[#323232] mt-5 rounded-[35px] text-white'>
      <div className="p-5">
        <div className='flex justify-center p-4 text-center mb-5'>
          <h1 className="text-2xl lg:text-4xl font-bold mb-0 text-center">Image to Text Converter</h1>
        </div>
        <div className='flex flex-col items-center justify-center text-center cursor-pointer'>
          <div 
            className="w-[400px] relative border-2 border-gray-300 border-dashed rounded-lg p-6 cursor-pointer" 
            id="dropzone"
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <input 
              type="file" 
              className="absolute inset-0 w-full h-full opacity-0 z-50 cursor-pointer" 
              onChange={handleImageChange}
              accept="image/*"
            />
            <div className="text-center">
              <img className="mx-auto h-12 w-12" src="https://www.svgrepo.com/show/357902/image-upload.svg" alt="Upload Icon" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                <label htmlFor="file-upload" className="relative cursor-pointer">
                  <span className='text-white'>Drag and drop</span>
                  <span className="text-blue-300"> or browse</span>
                  <span className='text-white'> to upload</span>
                  <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                </label>
              </h3>
              <p className="mt-1 text-sm text-white">
                PNG, JPG, GIF up to 10MB
              </p>
            </div>
         
          </div>
        </div>
        <div className='flex justify-center mt-10'>
          <button 
            onClick={convertImageToText} 
            type="button" 
            className="text-white items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Convert
          </button>
        </div>
        <div>
        {selectedImage && (
              <img src={selectedImage} className="mt-4 mx-auto w-auto h-auto rounded" alt="Preview" id="preview" />
            )}
        </div>
        {loading && (
          <div className="flex justify-center items-center h-screen">
            <footer></footer>
            <div className="loader"></div>
          </div>
        )}
      
        <div id="result" className="mt-4 flex flex-col items-center">
          {extractedText && (
            <>
              <textarea 
                value={extractedText} 
                readOnly 
                rows="6" 
                cols="50" 
                className="w-full p-2 border border-gray-300 bg-slate-700 rounded-md mb-4"
              />
              <div className="flex gap-4">
                <button 
                  onClick={downloadTextFile} 
                  type="button" 
                  className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                >
                  Download
                </button>
                <button 
                  onClick={copyToClipboard} 
                  type="button" 
                  className={`text-white ${copySuccess ? 'bg-green-500' : 'bg-gradient-to-br from-green-500 to-lime-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800'} font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
                >
                  {copySuccess ? 'Copied!' : 'Copy'}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Main;