import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); 

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); 
  };

  return (
    <div>
      <header className="bg-transparent py-2">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="md:flex flex-row md:items-center md:gap-12">
              <Link to="/">
              <a className="block text-teal-600">
                <span className="sr-only">Home</span>
                <div className='flex'>
                <img className='h-[50px] md:h-[60px]' src="logo.png" alt="logo" />
                <h2 className='text-white text-2xl font-semibold mt-2 md:mt-5 px-2 font-serif'>Image to Text</h2>
                </div>
              </a>
              </Link>
            </div>

            <div className="hidden md:block">
              <nav aria-label="Global">
                <ul className="flex  items-center gap-6 text-lg">
                  <Link to="/">
                  <li>
                    <a className="text-white font-semibold"> Home </a>
                  </li>
                  </Link>
                  <Link to="/about">
                  <li>
                    <a className="text-white font-semibold"> About </a>
                  </li>
                  </Link>
                  <Link to="/privacy-policy">
                  <li>
                    <a className="text-white font-semibold"> Privacy Policy </a>
                  </li>
                  </Link>
                  <Link to="/terms-and-conditions">
                  <li>
                    <a className="text-white font-semibold"> Terms and Conditions </a>
                  </li>
                  </Link>
                </ul>
              </nav>
            </div>

            <div className="flex items-center gap-4">
              <div className=" sm:gap-4 hidden sm:block">
                <Link to="/contact">
                <a
                  className="rounded-md bg-blue-600 hover:bg-blue-800 px-5 py-2.5 text-sm font-medium text-white shadow"
                  
                >
                  Contact
                </a>
                </Link>
              </div>

              <div className="block md:hidden">
                <button onClick={toggleMenu} className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        
        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden h-[200px]">
            <nav aria-label="Global">
              <ul className="flex flex-col items-center gap-4 text-sm">
                <Link to='/'>
                <li>
                  <a className="text-white font-semibold text-lg"> Home </a>
                </li>
                </Link>
                <Link to="/about">
                <li>
                  <a className="text-white font-semibold text-lg"> About </a>
                </li>
                </Link>
                <Link to="/privacy-policy">
                <li>
                  <a className="text-white font-semibold text-lg" href="#"> Privacy Policy </a>
                </li>
                </Link>
                <Link to="/terms-and-conditions">
                <li>
                  <a className="text-white font-semibold text-lg"> Terms and Conditions </a>
                </li>
                </Link>
                <div className=" block sm:hidden mt-2">
                  <Link to="/contact">
                <a
                  className="rounded-md bg-blue-600 hover:bg-blue-800  px-5 py-2.5 text-sm font-medium text-white shadow"
                  
                >
                  Contact
                </a>
                </Link>
              </div>
              </ul>
             
            </nav>
          </div>
        )}
      </header>
    </div>
  );
}

export default NavBar;
